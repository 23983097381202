body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-background);
  border-radius: 3px;
}

.React-logo {
  height: 30px;
  margin-left: -5px;
}

:root {
  --boder-radius: 5px;
}

.theme-light {
  --main-background: #ffffff;
  --secondary-background: #d3d3d3;
  --gradient-color: linear-gradient(135deg, var(--secondary-background) 0%, #e1e1e1 100%);
  --primary-text: #000;
  --secondary-text: rgb(10, 10, 10);
  --secondary-text-hover: #000;
  --box-shadow: rgba(0, 0, 0, 0.17);
}

.theme-dark {
  --main-background: #2F4550;
  --secondary-background: #315261;
  --gradient-color: linear-gradient(135deg, var(--secondary-background) 0%, #19252a 100%);
  --primary-text: #ffffff;
  --secondary-text: rgb(240, 240, 240);
  --secondary-text-hover: rgb(250, 250, 250);
  --box-shadow: rgba(255, 255, 255, 0.17);
}

.icon-custom {
  line-height: 40px;
}
