.cards {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.card-container {
  padding: 1rem;
}

.card {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 0.5rem;
  transition-duration: 0.5s;
}

.card:hover {
  transform: translateY(-10px);
  transition-duration: 0.5s;
}

.card img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  height: 240px;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1.0) 95%, rgba(0,0,0,0.0)); */
}

.card .header {
  font-family: "Quicksand", Arial, sans-serif;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card .header h3 {
  font-family: "Quicksand", Arial, sans-serif;
  margin-bottom: 0px;
}

.card .header .grade {
  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  box-shadow: 0 0 1px #FBAB7E;
  color: #333;
  padding: 10px 15px;
  border-radius: 50px;
  font-weight: 500;
  width: fit-content;
  min-width: fit-content;
  margin-left: 10px
}

.card .tags {
  display: flex;
  flex-wrap: wrap;
}

.card .tags .tag {
  border-radius: 15px;
  background-color: #8EC5FC;
  background-image: linear-gradient(62deg, #8EC5FC 0%, #c3e3fc 100%);
  box-shadow: 0 0 1px #8EC5FC;
  color: #333;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 5px 15px;
}

.card .tags .tag:has(+.tag) {
  margin-right: 10px;
}

.card .card-content p {
  text-align: justify;
  margin-bottom: 10px;
}

.card .card-footer {
  text-align: right;
}

.card .card-footer a.link {
  display: inline-flex;
  align-items: center;
}

.card .card-footer a.link i {
  margin-right: 5px;
}